export default [
  {
    prop: 'user_id',
    label: 'User ID',
    type: 'text',
    link: 'irace.vn/races/users',
    external: true
  },
  {
    prop: 'name',
    label: 'User',
    type: 'text'
  },
  {
    prop: 'detail_id',
    label: 'Activity ID',
    type: 'text',
    link: 'www.strava.com/activities',
    external: true
  },
  {
    prop: 'distance',
    label: 'Distance (km)',
    type: 'text'
  },
  {
    prop: 'moving_time',
    label: 'Time (m)',
    type: 'text'
  },
  {
    prop: 'pace',
    label: 'Pace',
    type: 'text'
  }
]
