<template>
  <div class="row">
    <div class="col-md-12 card p-0">
      <div class="filter-group-bill">
        <div class="row">
          <div class="col-md-3 filter-col">
            <my-select
              class="full-width"
              :attribute="races"
              placeholder="Chọn cuộc đua"
              v-model="race_id">
            </my-select>
          </div>
          <div class="col-md-3 filter-col">
            <el-input
              placeholder="Activity ID"
              v-model="detail_id">
            </el-input>
          </div>
          <div class="col-md-3 filter-col">
            <p-button class="full-width btn-load-data" @click="loadOrders">
              <i class="fa fa-plus"></i>
              Load dữ liệu
            </p-button>
          </div>
        </div>
      </div>
      <div class="card-body" v-if="min_pace && max_pace">
        <div class="col-xs-3">Min Pace: <b>{{min_pace}}</b></div>
        <div class="col-xs-3">Max Pace: <b>{{max_pace}}</b></div>
      </div>
      <div class="card-body row p-0">
        <div class="col-sm-12">
          <my-table :columnDefs="columnDefs" v-bind:data-rows="orders" :actions="actions" :actionsTable="actionsTable"/>
        </div>
      </div>
    </div>
    <loading :active.sync="isLoading"></loading>
    <toast position="se"></toast>
  </div>
</template>
<script>
  import { Message, MessageBox, Dialog, Button, Input } from 'element-ui';
  import MyTable from 'src/components/UIComponents/Table.vue';
  import cheatSchemas from './cheat-schemas';
  import dtHelper from 'src/helpers/datatable';
  import MySelect from 'src/components/UIComponents/Select';
  import Loading from 'vue-loading-overlay';
  import { Toast } from 'vuex-toast'
  import { mapActions, mapGetters } from 'vuex'
  import { ADD_TOAST_MESSAGE } from 'vuex-toast'
  import 'vue-loading-overlay/dist/vue-loading.css';

  export default {
    components: {
      MyTable,
      Message,
      MySelect,
      ElDialog: Dialog,
      ElButton: Button,
      ElInput: Input,
      Loading,
      Toast
    },
    computed: {
      races() {
        let data = this.$store.state.races;
        let obj = {};
        obj.options = [];
        for (let i of data) {
          obj.options.push({
            title: i.title,
            value: i.id
          });
        }
        return obj;
      },
      orders() {
        let rows = this.$store.state.activities_cheat || [];
        let data = [];
        for (let item in rows) {
          data.push(rows[item]);
        }
        return data;
      }
    },
    data() {
      return {
        columnDefs: dtHelper.buildColumDefs(cheatSchemas),
        currentSelectRow: [],
        sellectAddCollections: [],
        dialogAddTransportersVisible: false,
        isLoading: false,
        min_pace: 0,
        max_pace: 0,
        race_id: '',
        user_id: '',
        detail_id: '',
        actionsTable: [],
        actions: [
          {
            type: 'danger',
            icon: 'nc-icon nc-simple-remove',
            callback: this.remove
          }
        ]
      }
    },
    methods: {
      loadOrders() {
        if (!this.race_id) {
          return Message({
            type: 'error',
            message: 'Chưa chọn cuộc đua'
          });
        }

        if (!this.user_id && !this.detail_id) {
          return Message({
            type: 'error',
            message: 'Vui lòng nhập User ID hoặc Activity ID'
          });
        }

        this.loadActivitiesCheat();
      },
      loadActivitiesCheat() {
        this.isLoading = true;
        let data = {
          race_id: this.race_id,
          user_id: this.user_id,
          detail_id: this.detail_id
        }
        this.$store.dispatch('fetchActivitiesCheat', data).then((res) => {
          console.log(res);
          this.isLoading = false;
          this.min_pace = 0;
          this.max_pace = 0;
          if (res.success) {
            this.min_pace = res.min_pace;
            this.max_pace = res.max_pace;
          } else {
            Message({
              type: 'error',
              message: res.message
            });
          }
        });
      },
      remove(index, row) {
        MessageBox.confirm('Xóa hoạt động?', 'Warning', {
          confirmButtonText: 'Đồng ý',
          cancelButtonText: 'Hủy bỏ',
          type: 'warning',
          center: true
        }).then(() => {
          let user_id = row.user_id;
          let data = {
            id: row.activity_id
          };
          this.$store.dispatch('removeActivity', data).then(res => {
            if (res.success) {
              this.toast(res.message, 'success');
              this.loadActivitiesCheat();
              this.$store.dispatch("calcDistanceUserRace", {
                user_id: user_id,
                race_id: this.race_id
              });
            } else {
              this.toast(res.message, "danger");
            }
          });
        }, error => {
          console.error(error);
        });
      },
      ...mapActions({
        addToast: ADD_TOAST_MESSAGE
      }),
      toast(text, type) {
        this.addToast({
          text,
          type: type,
          dismissAfter: 3000
        })
      },
    },
    mounted() {
      window.AP = this;
      this.$store.dispatch('fetchRaces');
      this.$store.dispatch('setPageTitle', 'Xử lý gian lận');
      this.$store.dispatch('setCurrentFilterUsers', []);
    },
    destroyed(){
      this.$store.dispatch('setCurrentActions', []);
    }
  }
</script>
<style lang="scss">
  .el-table .td-actions {
    button.btn {
      margin-right: 5px;
    }
  }
  
  .filter-group-bill {
    padding: 15px;
  }

  .btn-load-data {
    margin: 0;
  }

</style>
